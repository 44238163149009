import { put, takeLatest, call } from "redux-saga/effects";
import TokenService from "../../services/TokenService";
import {
  getAnonymousTokenErrorAction,
  setAppPropsAction,
  setRefreshStartedAction,
} from "./actions";
import { GET_ANONYMOUS_TOKEN_REQUEST } from "./constants";

export function* getAnonymousTokenSaga() : any {
  try {
    const response = yield call(TokenService.getAnonymousToken);
    yield put(setAppPropsAction(response.accessToken, response.refreshToken));
  } catch (error : any) {
    yield put(getAnonymousTokenErrorAction(error));
  } finally {
    yield put(setRefreshStartedAction(false));
  }
}

export default [takeLatest(GET_ANONYMOUS_TOKEN_REQUEST, getAnonymousTokenSaga)];
