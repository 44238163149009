import React, { FC, Fragment, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Chat from "../Chat/Chat";
import Home from "../Home/Home";
import { useDispatch, useSelector } from "react-redux";
import {
  setAppFlowAction,
  setAppPropsAction,
  setNicknameAction,
  setUserNameAction,
  setSelectedAccount,
  setSelectorOpened,
  setIsTobiOn,
  setIsLoading,
  setIsIdentitySelected,
  setHasUserInfoFinished,
  setNotificationBan,
  setShowGdprModal,
} from "./actions";
import {
  MVA_FLOW,
  WEB_FLOW,
  SUB_USER,
  POWER_USER,
  USER,
  FIXED_USER,
  PREPAID,
  NONVODAFONE,
  HYBRID,
  TOBI_CONFIG,
  MAINTENANCE_BANNER,
  ALLOWED_USERS,
  OBTAIN_TOKEN_URL,
  CONVERT_TOKEN_URL,
  USER_INFO_URL,
  WEB_LOGIN_REDIRECT,
  WEB_LOGOUT_REDIRECT,
} from "../../constants";
import axios from "axios";
import { IState } from "./model";
import MaintenanceBanner from "../../Components/MaintenanceBanner/MaintenanceBanner";
import NotFound from "../NotFound/NotFound";

const Main: FC = () => {
  const dispatch = useDispatch();
  let { appFlow, accessToken, refreshToken } = useSelector((state: IState) => state.app);
  const url = window.location.search;
  let params = new URLSearchParams(url);
  let flow: string | null = null;
  if (url) {
    flow = params.get("flow");
  }
  let [showMaintenanceBanner, setShowMaintenanceBanner] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  let dataLayer = window.dataLayer || [];

  useEffect(() => {
    if (flow && flow === "withAuth" && window.ReactNativeWebView) {
      dispatch(setIsLoading(true));
      dispatch(setSelectorOpened(false));
      dispatch(setAppFlowAction(MVA_FLOW));
      waitForTokens(MVA_FLOW);
    } else {
      // Web flow
      dispatch(setNicknameAction("Utilizator WEB"));
      dispatch(setUserNameAction("Utilizator WEB"));
      dispatch(setAppFlowAction(WEB_FLOW));
      waitForTokens(WEB_FLOW);
    }
  }, []);

  const waitForTokens = (channel: string) => {
    const tokensId = params.get("tokens_id");
    if (channel === MVA_FLOW) {
      const fullName = localStorage.getItem("fullName");
      const appVersion = localStorage.getItem("appVersion");
      if (tokensId && appVersion) {
        if (fullName) {
          // if we have firstName & lastname we set them on store so we can send it on prebind
          dispatch(setUserNameAction(removeDiacritics(fullName)));
          dispatch(setNicknameAction(removeDiacritics(fullName)));
        } else {
          dispatch(setUserNameAction("Utilizator MVA"));
          dispatch(setNicknameAction("Utilizator MVA"));
        }
        obtainTobiTokens(tokensId, MVA_FLOW);
        dispatch(setIsLoading(false));
      } else {
        setTimeout(() => waitForTokens(MVA_FLOW), 1000);
      }
    } else {
      if (tokensId) {
        obtainTobiTokens(tokensId, WEB_FLOW);
      } else {
        convertToken();
      }
    }
  };

  const obtainTobiTokens = async (tokensId: string, channel: string) => {
    const data = new URLSearchParams({ tokens_id: tokensId });
    try {
      const response = await axios
        .post(OBTAIN_TOKEN_URL, data, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: process.env.REACT_APP_OBTAIN_TOKEN_AUTH,
          },
        })
      if (
        response.status === 200 &&
        response.data.accessToken &&
        response.data.refreshToken
      ) {
        dispatch(
          setAppPropsAction(
            response.data.accessToken,
            response.data.refreshToken
          )
        );
        getUserInfo(response.data.accessToken, channel);
      } else {
        handleError(channel);
      }
    }
    catch (error: any) {
      //Error handling on index.tsx
    };
  };

  const convertToken = () => {
    axios
      .get(CONVERT_TOKEN_URL, {
        withCredentials: true,
      })
      .then((response) => {
        if (
          response.status === 200 &&
          response.data.accessToken &&
          response.data.refreshToken
        ) {
          dispatch(
            setAppPropsAction(
              response.data.accessToken,
              response.data.refreshToken
            )
          );
          getUserInfo(response.data.accessToken, WEB_FLOW);
        } else {
          handleError(WEB_FLOW);
        }
      })
      .catch(() => {
        //Error handling on index.tsx
      });
  };

  const getUserInfo = async (accToken: string, channel: string) => {
    try {
      const response = await axios
        .get(USER_INFO_URL, {
          headers: {
            Authorization: `Bearer ${accToken}`,
          },
        })
      if (
        MAINTENANCE_BANNER &&
        !ALLOWED_USERS.includes(response.data.vfSsoEmail)
      ) {
        setShowMaintenanceBanner(true);
      } else {
        setShowMaintenanceBanner(false);
      }
      if (
        (response.data.vfSsoFirstName || response.data.vfContactGivenName) &&
        (response.data.vfSsoLastName || response.data.vfContactFamilyName)
      ) {
        const firstName =
          response.data.vfSsoFirstName || response.data.vfContactGivenName;
        const lastName =
          response.data.vfSsoLastName || response.data.vfContactFamilyName;
        dispatch(
          setUserNameAction(removeDiacritics(`${firstName} ${lastName}`))
        );
        dispatch(
          setNicknameAction(removeDiacritics(`${firstName} ${lastName}`))
        );
      }
      if (response.data.vfCRMRole || response.data.vfOdsBan) {
        if (channel === WEB_FLOW) {
          const user = getEligibleType(response.data);
          if (
            user &&
            TOBI_CONFIG[user as keyof typeof TOBI_CONFIG].isTobiOn
          ) {
            dispatch(setIsTobiOn(true));
          } else {
            dispatch(setIsTobiOn(false));
          }
        }
        if (response.data.vfCRMRole === FIXED_USER) {
          if (response.data.upcBanBaseId && response.data.upcBanExtensionId) {
            dispatch(
              setSelectedAccount(
                response.data.upcBanBaseId,
                response.data.vfEntityID
              )
            );
            dispatch(
              setNotificationBan(
                `${response.data.upcBanBaseId}-${response.data.upcBanExtensionId}`
              )
            );
          } else if (response.data.vfOdsBan) {
            dispatch(
              setSelectedAccount(
                response.data.vfOdsBan,
                response.data.vfEntityID
              )
            );
            dispatch(setNotificationBan(`${response.data.vfOdsBan}`));
          }
        } else if (
          response.data.vfCRMRole === SUB_USER ||
          response.data.vfCRMRole === POWER_USER ||
          (response.data.vfCRMRole === USER &&
            (response.data.vfIsPrepaid === "true" ||
              response.data.vfIsHybrid === "true"))
        ) {
          dispatch(
            setSelectedAccount(
              response.data.vfPhoneNumber,
              response.data.vfEntityID
            )
          );
        } else {
          dispatch(
            setSelectedAccount(
              response.data.vfOdsBan,
              response.data.vfEntityID
            )
          );
          if (response.data.vfCRMRole !== USER) {
            dispatch(setNotificationBan(`${response.data.vfOdsBan}`));
          }
        }
        dispatch(setSelectorOpened(false));
        dispatch(setIsIdentitySelected(true));
        if (channel === MVA_FLOW) {
          let gdprModalShowed =
            isJson(localStorage.getItem("modalShowed")) || {};
          const dateGdprModalShowed = gdprModalShowed[
            response.data.vfEntityID
          ]
            ? new Date(gdprModalShowed[response.data.vfEntityID])
            : null;
          const now = new Date(new Date().toDateString());
          if (
            (dateGdprModalShowed &&
              now.setUTCHours(0) !== dateGdprModalShowed.setUTCHours(0)) ||
            !dateGdprModalShowed
          ) {
            dispatch(setShowGdprModal(true));
          }
        }
      } else {
        if (channel === WEB_FLOW) {
          dispatch(setSelectorOpened(true));
        }
      }
      dispatch(setHasUserInfoFinished(true));
      dispatch(setIsLoading(false));
    }
    catch (error: any) {
      //Error handling on index.tsx
    };
  };

  const isFlexUser = (userInfo: any) => {
    return !!(
      userInfo &&
      userInfo.vfCustomerType === PREPAID &&
      userInfo.isPrimeSubscriber === "true"
    );
  };

  const isCBUUser = (userInfo: any) => {
    return userInfo && userInfo.vfCustomerType !== NONVODAFONE;
  };

  const isEBUUser = (userInfo: any) => {
    return !!(
      userInfo &&
      userInfo.vfCustomerType === NONVODAFONE &&
      userInfo.vfEBUMigrated
    );
  };

  const isUPC = (userInfo: any) => {
    return (
      userInfo?.vfCRMRole === "FixedContractOwner" ||
      userInfo?.vfResourceType === "VTV Service ID"
    );
  };

  const isNonVodafoneUser = (userInfo: any) => {
    return (
      userInfo &&
      userInfo?.vfCustomerType === NONVODAFONE &&
      !userInfo?.vfEBUMigrated
    );
  };

  const isHybrid = (userInfo: any) => {
    return userInfo?.vfCustomerType?.toLowerCase() === HYBRID;
  };

  const getEligibleType = (userInfo: any) => {
    if (isNonVodafoneUser(userInfo)) return "nonvodafone";
    if (isFlexUser(userInfo)) return "vodFlex";
    if (isUPC(userInfo)) return "upc";
    if (isEBUUser(userInfo)) return "ebu";
    if (isCBUUser(userInfo)) return userInfo?.vfCustomerType;
    if (isHybrid(userInfo)) return "hybrid";
    return null;
  };

  const isJson = (str: string | null) => {
    if (str) {
      let parsedString;
      try {
        parsedString = JSON.parse(str);
      } catch (e) {
        // handle error if needed
      }
      return parsedString;
    }
    return false;
  };

  const removeDiacritics = (fullName: string) => {
    return fullName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const handleWebError = () => {
    if (window.location !== window.parent.location) {
      window.parent.postMessage(
        "REFRESH TOKENS ID",
        process.env.REACT_APP_ECARE_URL!
      );
    } else {
      window.location.replace(WEB_LOGIN_REDIRECT);
    }
  };

  const handleMvaError = () => {
    window.ReactNativeWebView.postMessage("REFRESH TOKENS");
    window.ReactNativeWebView.postMessage("REFRESH TOKENS ID");
  };

  const handleError = (channel: string) => {
    if (channel === WEB_FLOW) {
      handleWebError();
    }
    if (channel === MVA_FLOW) {
      handleMvaError();
    }
  };

  const logoutClicked = (pageName: string) => {
    let clickDataObject = {
      event: "microData", // required
      event_name: "action_click", // required
      click_element_name: "Deconectare", // required name of the clicked element
      page_name: pageName, // required name of the page where click happened
    };
    dataLayer.push(clickDataObject);
  };

  const handleLogout = (pageName: string) => {
    logoutClicked(pageName);
    axios
      .post(
        WEB_LOGOUT_REDIRECT,
        {
          accessToken: accessToken,
          refreshToken: refreshToken,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        setLoggedIn(false);
        dispatch(setAppPropsAction(null, null));
        window.location.replace(response.data.afterLogoutUrl);
      })
      .catch((error) => {
        console.log(error)
      });
  };

  return (
    <Fragment>
      {appFlow && (
        <Fragment>
          {showMaintenanceBanner ? (
            <MaintenanceBanner setLoggedIn={setLoggedIn} loggedIn={loggedIn} handleLogout={handleLogout} flow={flow} />
          ) : (
            <Routes>
              <Route path="/" element={<Home getUserInfo={getUserInfo} setLoggedIn={setLoggedIn} loggedIn={loggedIn} handleLogout={handleLogout} flow={flow} />} />
              <Route path="/chat" element={<Chat getUserInfo={getUserInfo} handleLogout={handleLogout} flow={flow} loggedIn={loggedIn} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Main;
