import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import TobiSad from "../../Components/TobiIcons/TobiSad";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Container fluid="md" className="text-center mt-4">
      <Row className="py-3">
        <Col>
          <TobiSad />
          <h5 className="mt-3">Ups... Se pare că pagina accesată nu există sau nu ai dreptul să o accesezi.</h5>
          <h5>Poți reveni la pagina principală apăsând butonul de mai jos</h5>
          <Button
            type="button"
            variant="secondary"
            className="mt-2"
            onClick={() => navigate("/")}
          >
            Înapoi la pagina principală
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
